<messages>["../BookEntry"]</messages>

<template>
  <v-select
    v-model="internalValue"
    :clearable="clearable"
    :loading="isLoading"
    :label="$t('create.actionType')"
    :items="actionTypes"
    :disabled="actionTypes.length <= 1"
    :error-messages="errorMessages"/>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    props: {
      errorMessages: {
        type: Array,
        default: () => []
      },

      value: {
        type: String,
        default: undefined
      },
      clearable: {
        type: Boolean,
        default: false
      },
      hideRefund: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isLoading: false
      }
    },
    computed: {
      ...mapState ('cache', ['accountingActions']),
      internalValue: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },
      actionTypes () {
        return this.accountingActions
          ? this.accountingActions.filter ((i) => !this.hideRefund || !i.refund).map (t => ({
            value: t.name,
            text: this.$t (`create.actionTypes.${t.name.replace (/\./g, '')}`)
          }))
          : []
      }
    },
    created () {
      if (!this.accountingActions) this.loadActions ()
    },
    methods: {
      ...mapMutations ('cache', ['setAccountingActions']),
      ...mapActions ('request', ['fetchData']),

      loadActions () {
        this.isLoading = true

        this.fetchData ({
          op: 'account/load-actions',
          cb: data => {
            this.setAccountingActions (data.actions)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
