<messages>["../Tariff"]</messages>
<template>
  <confirmation-dialog
    v-model="showDialog"
    :headline="$t('title.followup')"
    :is-loading="isLoading"
    :error-msg="errorMessage"
    @ok="onOk">
    <template #activator="{ on }">
      <slot name="activator" :on="on"/>
    </template>
    <span class="text-subtitle-1">{{ $t('followup.currentFilter') }}:</span>
    <tariff-filter-view :filter="filter"/>
    <v-row class="mt-5" justify="space-between">
      <v-date-picker
        v-model="day"
        scrollable
        first-day-of-week="1"
        :locale="$i18n.locale"
        :allowed-dates="futureOrEqual"/>
      <v-time-picker
        v-model="time"
        :locale="$i18n.locale"/>
    </v-row>
  </confirmation-dialog>
</template>

<script>
  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'
  import TariffFilterView
    from '@/app/pages/Account/components/TariffFilterView'
  import {future} from '@/app/core/components/DatePickerField'
  import {mapActions, mapMutations} from 'vuex'

  const DIFF = 5
  const getFuture = (offset = 0) => new Date (new Date ().getTime () + (DIFF + offset) * 60000)

  export default {
    name: 'FollowupTariffDialog',
    components: {
      ConfirmationDialog,
      TariffFilterView
    },

    props: {
      filter: {
        type: Object,
        default: () => ({})
      }
    },

    data () {
      return {
        showDialog: false,
        isLoading: false,
        day: this.formatDateShort (getFuture (1), true),
        time: this.formatTime (getFuture (1), true),
        refreshValid: false
      }
    },

    computed: {
      errorMessage () {
        return this.validDate ? '' : this.$t ('followup.futureError')
      },
      date () {
        return new Date (`${this.day}T${this.time}Z`)
      },
      validDate () {
        // eslint-disable-next-line no-unused-expressions
        this.refreshValid
        return this.date > getFuture ()
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      onOk: function () {
        this.refreshValid = !this.refreshValid

        if (!this.validDate) {
          return
        }

        this.isLoading = true
        this.fetchData ({
          op: 'tariff/follow-up',
          params: {
            ...this.filter,
            from: this.date
          },
          cb: data => {
            this.displaySuccessMessage (this.$t ('followup.success.noWarning'))

            this.$emit ('newDoc', {
              doc: data.tariffData,
              haveEnded: data.hasEnded,
              invalidStart: data.invalidStart
            })
            this.showDialog = false
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },
      futureOrEqual (date) {
        return future (date) || this.formatDateShort (getFuture (), true) === date
      }
    }
  }
</script>

<style scoped>

</style>
