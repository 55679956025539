<messages>["../BookEntry"]</messages>

<template>
  <v-select
    v-model="internalValue"
    :clearable="clearable"
    :label="$t('label.clientType')"
    :items="options"
    :disabled="options.length <= 1"
    :error-messages="errorMessages"/>
</template>

<script>

  const ClientTypes = [
    'Member',
    'Reseller',
    'Partner',
    'Internal',
    'Agent'
  ]

  export default {
    props: {
      errorMessages: {
        type: Array,
        default: () => []
      },

      value: {
        type: String,
        default: undefined
      },
      clearable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },
      options () {
        return ClientTypes.map ((i) => ({
          value: i,
          text: this.$t (`clientTypes.${i}`)
        }))
      }
    }
  }
</script>

<style>

</style>
