import { render, staticRenderFns } from "./FollowupTariffDialog.vue?vue&type=template&id=8d7af636&scoped=true&"
import script from "./FollowupTariffDialog.vue?vue&type=script&lang=js&"
export * from "./FollowupTariffDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d7af636",
  null
  
)

/* custom blocks */
import block0 from "./FollowupTariffDialog.vue?vue&type=custom&index=0&blockType=messages"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VDatePicker,VRow,VTimePicker})
