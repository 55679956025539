<messages>["../BookEntry"]</messages>

<template>
  <v-select
    v-model="internalValue"
    :clearable="clearable"
    multiple
    :label="$t('create.objectType')"
    :items="objectTypes"
    :disabled="objectTypes.length <= 1"
    :error-messages="errorMessages"/>
</template>

<script>

  const objectTypes = [
    'contact',
    'domain',
    'email',
    'host',
    'protection',
    'watch',
    'application',
    'client'
  ]

  export default {
    props: {
      errorMessages: {
        type: Array,
        default: () => []
      },

      value: {
        type: [String, Array],
        default: undefined
      },
      clearable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },
      objectTypes () {
        return objectTypes.map ((t) => ({
          value: t,
          text: this.$t (`create.objectTypes.${t}`)
        }))
      }
    }
  }
</script>

<style>

</style>
