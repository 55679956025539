<messages>["./Tariff"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card>
        <form @submit.prevent="submitTariffs()">
          <v-card-title primary-title>
            <div>
              <div
                v-t="'title.view'"
                class="text-h5"/>
              <div
                v-t="'explain.text1'"
                class="cgwng-subheading"/>
              <div
                v-t="'explain.text2'"
                class="cgwng-subheading"/>
              <div
                v-t="'explain.text3'"
                class="cgwng-subheading"/>
            </div>
          </v-card-title>

          <v-card-text v-if="createCount + deleteCount + updateCount > 0">
            <div>{{ $t ('result.createCount') }} {{ createCount }}</div>
            <div>{{ $t ('result.deleteCount') }} {{ deleteCount }}</div>
            <div>{{ $t ('result.updateCount') }} {{ updateCount }}</div>
          </v-card-text>

          <v-card-text>
            <v-row>
              <tariff-filter v-if="doFilter" v-model="filter"/>

              <v-col v-if="doFilter" cols="12">
                <v-row justify="end">
                  <followup-tariff-dialog :filter="filter" @newDoc="newDoc">
                    <template #activator="{on}">
                      <v-btn text primary v-on="on">
                        {{ $t ('followup.btn') }}
                        <v-icon>schedule</v-icon>
                      </v-btn>
                    </template>
                  </followup-tariff-dialog>
                </v-row>
              </v-col>
              <v-col
                class="align-start"
                cols="12" md="4">
                <v-btn
                  text
                  :loading="isLoadingInput"
                  @click="onClickDownloadInput">
                  {{ $t ('download.button.input') }}
                  <v-icon>get_app</v-icon>
                </v-btn>
              </v-col>
              <v-col
                class="align-start"
                cols="12" md="4">
                <v-btn
                  text
                  :loading="isLoadingFile"
                  @click="onClickDownloadFile">
                  {{ $t ('download.button.file') }}
                  <v-icon>get_app</v-icon>
                </v-btn>
              </v-col>
              <v-col
                class="align-start"
                cols="12" md="4">
                <v-checkbox v-model="doFilter" :label="$t ('view.doFilter')"/>
              </v-col>
            </v-row>
            <v-alert v-if="showFollowUpWarn" type="warning">
              {{ $t('followup.success.withWarning') }}
            </v-alert>
            <xml
              v-model="tariffs"
              close-tags
              :lines="inputFieldSize"
              :placeholder="$t ('input')"
              :error-messages="requiredErrors(
                'tariffs', 'label.required')"
              @input="$v.tariffs.$touch()"/>

            <span class="red--text">
              {{ requiredErrors ('tariffs', 'label.required').join (';') }}
            </span>

            <v-alert
              v-model="error"
              transition="slide-y-transition"
              type="error"
              dismissible>
              <pre class="inlineCode">
                {{ errorMsg }}
              </pre>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.submit'"
              color="primary"
              :loading="isLoadingSave"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapMutations, mapGetters, mapState} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import Xml from '@/app/core/components/CodeEditor/Xml'

  import errorTranslator from '@/app/services/errortranslator'
  import TariffFilter, {getDefaultFilter} from './components/TariffFilter.vue'
  import FollowupTariffDialog
    from '@/app/pages/Account/components/FollowupTariffDialog'

  export default {
    name: 'Tariff',

    components: {
      BaseLayout,
      Xml,
      TariffFilter,
      FollowupTariffDialog
    },

    mixins: [validationMixins],

    data: () => ({
      tariffs: '',
      downloadedTariffs: '',
      deleteCount: 0,
      createCount: 0,
      updateCount: 0,
      error: false,
      errorMsg: '',
      isLoadingSave: false,
      isLoadingInput: false,
      isLoadingFile: false,
      inputFieldSize: 25,
      filter: null,
      doFilter: false,
      showFollowUpWarn: false
    }),

    validations: {
      tariffs: {required}
    },

    computed: {
      ...mapGetters ('create', [
        'getTariffCreateData'
      ]),
      ...mapState ('filter', [
        'tariffSearchQuery'
      ])
    },

    watch: {
      tariffs (newValue) {
        this.setTariffCreateDate (newValue)
      },
      filter: {
        handler (newVal) {
          this.setTariffSearchQuery ({
            doFilter: this.doFilter,
            query: newVal
          })
          this.setQueryParams ()
        },
        deep: false
      },
      doFilter (newVal) {
        this.setTariffSearchQuery ({
          doFilter: newVal,
          query: this.filter
        })
        this.setQueryParams ()
      },
      '$route.query.query': {
        handler () {
          this.parseFromQuery ()
        }
      },
      '$route.query.doFilter': {
        handler () {
          this.parseFromQuery ()
        }
      }
    },

    created () {
      this.tariffs = this.getTariffCreateData
      this.parseFromQuery ()
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        setTariffCreateDate: 'create/setTariffCreateData',
        setTariffSearchQuery: 'filter/setTariffSearchQuery'
      }),

      ...mapActions ('request', [
        'fetchData'
      ]),

      newDoc (data) {
        this.tariffs = data.doc
        this.showFollowUpWarn = data.haveEnded || data.invalidStart
      },

      submitTariffs () {
        if (!this.$v.$invalid) {
          this.isLoadingSave = true
          this.error = false
          this.showFollowUpWarn = false

          this.fetchData ({
            op: 'tariff/upload',
            params: {
              tariffData: this.tariffs
            },
            cb: data => {
              this.displaySuccessMessage (this.$t ('result.success'))
              this.deleteCount = data.stats.deletedTariffCount
              this.createCount = data.stats.createdTariffCount
              this.updateCount = data.stats.updatedTariffCount
            },
            cbError: data => {
              this.error = true
              this.errorMsg = data.errorData || errorTranslator (data)
            },
            cbFinal: () => {
              this.isLoadingSave = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      parseFromQuery () {
        const {doFilter, query} = this.$route.query

        this.doFilter = doFilter !== undefined ? doFilter === true || doFilter === 'true' : this.tariffSearchQuery?.doFilter || false

        try {
          this.filter = query
            ? JSON.parse (query)
            : this.tariffSearchQuery?.query
              ? JSON.parse (JSON.stringify (this.tariffSearchQuery?.query))
              : getDefaultFilter ()
        } catch (ex) {
          this.filter = this.tariffSearchQuery?.query
            ? JSON.parse (JSON.stringify (this.tariffSearchQuery?.query))
            : getDefaultFilter ()
        }
      },

      setQueryParams () {
        this.$router.replace ({
          name: this.$route.name,
          query: {
            doFilter: this.doFilter,
            query: JSON.stringify (this.filter)
          }
        }).catch (() => {
          // this function is needed to ignore the `NavigationDuplicated` exception thrown by `vue-router`
          // the solution is taken from
          // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
        })
      },

      async onClickDownloadInput () {
        this.isLoadingInput = true
        await this.downloadTariffs ()
        this.tariffs = this.downloadedTariffs
        this.isLoadingInput = false
      },

      async onClickDownloadFile () {
        this.isLoadingFile = true
        await this.downloadTariffs ()

        const blob =
          new window.Blob ([this.downloadedTariffs], {type: 'application/xml'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator?.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob (blob)
        } else {
          const downloadUrl = window.URL.createObjectURL (blob)

          const link = document.createElement ('a')
          link.href = downloadUrl
          link.download = 'tariffs.xml'
          document.body.appendChild (link)
          link.click ()

          // cleanup
          setTimeout (function () {
            document.body.removeChild (link)
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL (downloadUrl)
          }, 100)
        }
        this.isLoadingFile = false
        this.displaySuccessMessage (
          this.$t ('result.successDL'))
      },

      downloadTariffs () {
        this.showFollowUpWarn = false
        return this.fetchData ({
          op: 'tariff/download',
          params: {
            ...this.doFilter ? this.filter : {}
          },
          cb: data => {
            this.downloadedTariffs = data.tariffData
          }
        })
      }
    }
  }
</script>

<!--
================================================================================
  Styles (CSS)
================================================================================
-->

<style lang="scss" scoped>
@import '~@/app/scss/variables';

.cgwng-subheading {
  margin-top: $_space-md;
}
</style>
