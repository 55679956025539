<messages>["../Tariff", "../BookEntry"]</messages>
<template>
  <name-value-block :items="items"/>
</template>

<script>
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import {renderFilter, getDefaultFilter}
    from '@/app/pages/Account/components/TariffFilter'

  export default {
    name: 'TariffFilterView',
    components: {
      NameValueBlock
    },
    props: {
      filter: {
        type: Object,
        default: getDefaultFilter
      }
    },
    computed: {
      items () {
        return Object.keys (this.filter).map ((it) => ({
          name: this.$t (`followup.filters.${it}`),
          value: true,
          slotValue: renderFilter (it, this.filter[it], this)
        }))
      }
    }
  }
</script>

<style scoped>

</style>
