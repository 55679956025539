<messages>["../BookEntry"]</messages>

<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <registry-select :value="value.registryType" :label="$t ('label.registryType')" clearable @input="updateModel ('registryType', $event)"/>
        </v-col>
        <v-col cols="3">
          <object-action-select :value="value.action" clearable hide-refund @input="updateModel ('action', $event)"/>
        </v-col>
        <v-col cols="3">
          <client-type-select :value="value.clientType" clearable @input="updateModel ('clientType', $event)"/>
        </v-col>
        <v-col cols="3">
          <date-time-picker-field :value="value.date" now @input="updateModel ('date', $event)"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-checkbox v-model="additionalFilters" :label="$t ('label.additionalFilters')"/>
        </v-col>
        <v-col v-if="!additionalFilters" cols="8">
          <quick-filter
            key="quick"
            ma-4
            :items="getQuickFilterItems"
            @delete="onDeleteAdditionalFilter"
            @clicked="additionalFilters = true"/>
        </v-col>
      </v-row>
      <v-row v-show="additionalFilters">
        <v-col cols="4">
          <object-type-select :value="value.objectTypes" clearable @input="updateModel ('objectTypes', $event)"/>
        </v-col>
        <v-col cols="4">
          <registrar-select :value="value.registrar" :label="$t ('registrar.title.main')" clearable @input="updateModel ('registrar', $event)" @input:displayName="registrarDisplayName = $event"/>
        </v-col>
        <v-col cols="4">
          <apex-select :value="value.nameApex" :label="$t ('label.nameApex')" @input="updateModel ('nameApex', $event)"/>
        </v-col>
        <v-col cols="4">
          <phase-selector :value="value.phase" single @input="updateModel ('phase', $event)"/>
        </v-col>
        <v-col cols="4">
          <client-select :value="value.client" nullable show-inactive @input="updateModel ('client', $event)"/>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-btn text @click="resetFilter">
          {{ $t ('general.button.reset') }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'
  import RegistrySelect from '@/app/core/components/RegistrySelect.vue'
  import RegistrarSelect from './RegistrarSelect.vue'
  import ObjectActionSelect from './ObjectActionSelect.vue'
  import ClientTypeSelect from './ClientTypeSelect.vue'
  import ObjectTypeSelect from './ObjectTypeSelect.vue'
  import ApexSelect from '@/app/core/components/RegistryObject/domain/ApexSelect.vue'
  import PhaseSelector from '@/app/pages/Domain/components/PhaseSelector.vue'
  import ClientSelect from '@/app/core/components/ClientSelect.vue'

  import _isEqual from 'lodash/isEqual'
  import QuickFilter from '@/app/core/components/Search/QuickFilter.vue'
  import DateTimePickerField from '@/app/core/components/DateTimePickerField/DateTimePickerField.vue'
  import {EmptyMark} from '@/app/utils/string'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import RegistrarLink from '@/app/core/components/RegistrarLink'
  import ClientLink from '@/app/core/components/ClientLink'
  import {formatLauchPhase} from '@/app/pages/Domain/constants'
  import {formatDate} from '@/app/core/mixins/DatetimeHelper'

  export const getDefaultFilter = () => ({
    registryType: null,
    action: 'create',
    clientType: 'Member',
    objectTypes: ['domain'],
    registrar: -1,
    nameApex: null,
    phase: null,
    client: null,
    date: new Date ().getTime ()
  })

  export const renderFilter = (key, value, instance) => {
    if (!value) return instance.$createElement ('span', {}, value || EmptyMark)

    switch (key) {
      case 'registryType':
        return instance.$createElement (
          RegistryTypeLink,
          {props: {value: value}})
      case 'action':
        return instance.$createElement ('span', {},
                                        instance.$t (`create.actionTypes.${
                                          value.replace (/\./g, '')}`))
      case 'clientType':
        return instance.$createElement ('span', {},
                                        instance.$t (`clientTypes.${value}`))
      case 'objectTypes':
        return instance.$createElement (
          'span',
          {},
          value.map (i => instance.$t (`create.objectTypes.${i}`)).join (', '))
      case 'registrar':
        return instance.$createElement (RegistrarLink, {props: {id: value}})
      case 'nameApex':
        return instance.$createElement ('span', {}, value)
      case 'phase':
        return instance.$createElement ('span', {}, formatLauchPhase (value))
      case 'client':
        return instance.$createElement (ClientLink, {props: {id: value}})
      case 'date':
        return instance.$createElement ('span', {}, formatDate (value))
      default:
        return instance.$createElement ('span', {}, value || EmptyMark)
    }
  }

  const additionalKey = [
    'objectTypes',
    'registrar',
    'nameApex',
    'phase',
    'client'
  ]

  export default {
    components: {
      RegistrySelect,
      ObjectActionSelect,
      ClientTypeSelect,
      ObjectTypeSelect,
      RegistrarSelect,
      ApexSelect,
      PhaseSelector,
      ClientSelect,
      QuickFilter,
      DateTimePickerField
    },
    props: {
      value: {
        type: Object,
        default: () => getDefaultFilter ()
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        additionalFilters: false,
        defaultPayer: null,
        registrarDisplayName: null
      }
    },

    computed: {
      getQuickFilterItems () {
        const qFItems = []

        additionalKey.forEach ((key) => {
          if (!_isEqual (this.value[key], null)) {
            const getText = (key, item) => {
              if (Array.isArray (item)) {
                return item.join (', ')
              }

              if (key === 'phase' && item) {
                return item.name + (item.subname ? `/${item.subname}` : '')
              }

              if (key === 'registrar' && item) {
                return this.registrarDisplayName
              }

              return item
            }

            const addObject = {
              label: this.$t (`filter.additional.${key}`),
              text: getText (key, this.value[key]),
              prop: key
            }

            if (addObject) {
              qFItems.push (addObject)
            }
          }
        })
        return qFItems
      }
    },
    created () {
      this.loadDefaultRegistrar ().then (() => {
        if (this.value.registrar === -1) {
          this.updateModel ('registrar', this.defaultPayer)
        }
      })
    },
    methods: {
      ...mapActions ('request', [
        'fetchData'
      ]),

      resetFilter () {
        this.$emit ('input', {...getDefaultFilter (), registrar: this.defaultPayer})
      },

      updateModel (field, value) {
        this.$emit ('input', {...this.value, [field]: value})
      },

      onDeleteAdditionalFilter (index) {
        const {prop} = this.getQuickFilterItems[index]
        this.updateModel (prop, null)
      },

      loadDefaultRegistrar () {
        return this.fetchData ({
          op: 'getDefaultPayer',
          cb: data => {
            this.defaultPayer = data.id
          }
        })
      }
    }
  }
</script>
